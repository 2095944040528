@import 'apps/common/shared/styles/mixins';

.template404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__webDisconnected {
    padding-bottom: 34px;
  }

  &__title {
    @include font(24px, 600, 28px, var(--vkui--color_text_contrast), 1.2px);

    padding-bottom: 16px;
  }

  &__subTitle {
    @include font(16px, 400, 20px, var(--vkui--color_text_secondary), 1.2px);
  }

  &__button {
    margin-top: 30px;

    width: 199px;
    height: 48px;
  }
}
